import { Script } from 'gatsby';
import React from 'react';

const GeneralHead = ({ children }) => (
  <>
    {/* Mutiny */}
    {/* Note: Use a standard <script> tag for this so it's not affected by hydration */}
    <script>
      {`(function(){var a=window.mutiny=window.mutiny||{};if(!window.mutiny.client){a.client={_queue:{}};var b=["identify","trackConversion"];var c=[].concat(b,["defaultOptOut","optOut","optIn"]);var d=function factory(c){return function(){for(var d=arguments.length,e=new Array(d),f=0;f<d;f++){e[f]=arguments[f]}a.client._queue[c]=a.client._queue[c]||[];if(b.includes(c)){return new Promise(function(b,d){a.client._queue[c].push({args:e,resolve:b,reject:d});setTimeout(d,500)})}else{a.client._queue[c].push({args:e})}}};c.forEach(function(b){a.client[b]=d(b)})}})();`}
    </script>
    <script
      data-cfasync="false"
      src="https://client-registry.mutinycdn.com/personalize/client/e3a0a7cb7f1a7c76.js"
    />
    {/* Ketch */}
    <Script>
      {`!function(){window.semaphore=window.semaphore||[],window.ketch=function(){window.semaphore.push(arguments)};var e=new URLSearchParams(document.location.search),o=e.has("property")?e.get("property"):"website_smart_tag",n=document.createElement("script");n.type="text/javascript",n.src="https://global.ketchcdn.com/web/v2/config/shopmonkey/".concat(o,"/boot.js"),n.defer=n.async=!0,document.getElementsByTagName("head")[0].appendChild(n)}();`}
    </Script>
    {/* Save URL Parameters */}
    <Script
      defer
      src="https://cdn.jsdelivr.net/gh/gkogan/sup-save-url-parameters/sup.min.js"
    />
    {children}
  </>
);

export default GeneralHead;
