import { useLocation } from '@reach/router';
import React from 'react';
import PersistLink from '../components/PersistLink/PersistLink';

const Link = ({
  children,
  to,
  openInNewTab,
  className,
  role,
  'aria-label': ariaLabel,
}) => {
  const location = useLocation();

  if (!to) {
    return (
      <div role={role} className={className} aria-label={ariaLabel}>
        {children}
      </div>
    );
  }
  const isEmailLink = to.includes('mailto:');
  const isTelLink = to.includes('tel:');

  if (
    !openInNewTab &&
    !isEmailLink &&
    !isTelLink &&
    to.indexOf('shopmonkey.') > -1
  ) {
    const urlCleanup = to.replace(
      /(https?:\/\/www.shopmonkey\.(io|com))?/gi,
      ''
    );
    return (
      <PersistLink
        to={urlCleanup}
        state={{ prevPath: location.pathname }}
        className={className}
        aria-label={ariaLabel}
      >
        {children}
      </PersistLink>
    );
  }

  return (
    <a
      href={to}
      role={role}
      target={`_${openInNewTab ? 'blank' : 'self'}`}
      rel="noopener noreferrer"
      className={className}
      aria-label={to}
    >
      {children}
    </a>
  );
};

export default Link;
