import React from 'react';
import { Link as GatsbyLink } from 'gatsby';

const checkLinkType = to => {
  return /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g.test(
    to
  );
};

const removeDuplicate = url => {
  return new URLSearchParams(
    Object.fromEntries(new URLSearchParams(url))
  ).toString();
};

const PersistLink = ({ to, className, target, ...props }) => {
  if (typeof window !== 'undefined') {
    const link = to.includes('?')
      ? removeDuplicate(location.search)
      : to + location.search;
    if (checkLinkType(to)) {
      return (
        <a
          className={className}
          href={link}
          target={target ? target : '_blank'}
          rel="noopener noreferrer"
          onClick={props.onClick}
        >
          {props.children}
        </a>
      );
    }
    return (
      <GatsbyLink className={className} to={link} onClick={props.onClick}>
        {props.children}
      </GatsbyLink>
    );
  }
  return null;
};

export default PersistLink;
