import styled, { css } from 'styled-components';
import { AnnouncementWrapper } from '../../../styles/Components/Announcement/AnnouncementStyles';
import HeaderStyles from '../../../styles/Components/Header/HeaderStyles';
import { atMinWidth } from '../../../styles/atoms/breakpoints';
import { colors } from '../../../styles/atoms/colors';
import shadow from '../../../styles/atoms/shadows';
import { font } from '../../../styles/atoms/typography';

export const Container = styled.nav`
  width: 100%;
  height: auto;
  position: fixed;
  top: 120px;
  left: 0px;
  right: 0px;
  background: ${colors.base.white};
  z-index: -1;
  overflow: hidden;
  transform: translateY(-150%);
  transition: all 400ms ease-in-out;

  ${atMinWidth.xl`
    display: none;
  `}

  @media (max-width: 646px) {
    top: 127px;

    body:not(:has(${AnnouncementWrapper} + ${HeaderStyles})) & {
      top: 75px;
    }
  }

  &.active {
    transform: translateY(0);
    ${shadow('lg')}
  }

  ${props =>
    props?.scrolled &&
    css`
      top: 75px !important;
    `}
  ${props => {
    if (props?.activeDropdown !== null && props?.scrolled) {
      return css`
        height: calc(100vh - 75px);
      `;
    }
    if (props?.activeDropdown !== null && !props?.scrolled) {
      return css`
        height: calc(100vh - 127px);
      `;
    }
  }}
`;

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding: 11.5px 15px 0px 15px;

  ${atMinWidth.xl`
      padding: 11.5px 32px;
  `}

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const List = styled.ul`
  width: 100%;
  list-style: none;
  padding: 0px;
  margin: 0px 0px 16px;
`;

export const Buttons = styled.div`
  width: 100%;
  height: auto;
  padding: 16px 0px 31px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > a {
    ${font('text', 'xs', '700')}
    text-align: center;
    text-transform: uppercase;
    color: ${colors.gray[900]};
    margin-bottom: 16px;
  }

  .demo-btn {
    width: 100%;
    ${atMinWidth.sm`
      width: auto;
    `}
  }
`;
